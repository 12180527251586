import { takeEvery, put, call, select } from "redux-saga/effects";
import { FETCH_PAYMENT_PLANS_TRIGGER } from "../constants/paymentPlans";
import { fetchPaymentPlans } from "../util/api";
import { fetchPaymentPlansSuccess } from "../actions/paymentPlans";

export function* watchFetchPaymentPlans() {
    yield takeEvery(FETCH_PAYMENT_PLANS_TRIGGER, handleFetchPaymentPlans);
}

export function* handleFetchPaymentPlans() {
    const { token, accountId } = yield select((state) => {
        return {
            token: state.user.token,
            accountId: state.accounts.activeAccount.id
        };
    });

    const paymentPlans = yield call(fetchPaymentPlans, {
        token,
        accountId
    });

    yield put(fetchPaymentPlansSuccess(paymentPlans));
}
