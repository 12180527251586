import { takeEvery, put } from "@redux-saga/core/effects";
import { LOAN_PAGE_TRIGGER } from "../constants/page";
/* DEPRECATED */
import { fetchAccountStatementsTrigger } from "../actions/account";
/* DEPRECATED */
//import { fetchPaymentPlansTrigger } from "../actions/paymentPlans";

import { fetchDebtStatusTrigger } from "../actions/debtStatus";

export function* watchLoanPageLoad() {
    yield takeEvery(LOAN_PAGE_TRIGGER, handleLoanPageLoad);
}

export function* handleLoanPageLoad() {
    /* DEPRECATED */
    yield put(fetchAccountStatementsTrigger());
    /* DEPRECATED */
    // yield put(fetchPaymentPlansTrigger());
    yield put(fetchDebtStatusTrigger());
}
