/**
 * Files fetched with following names
 *
 * Lainaehdot_xxxx-xx-xx.pdf
 * sopimus_someHash.pdf
 * kuluttajaluottotiedot_someHash.pdf
 */
export const FILE_NAMES = {
    terms: "Lainaehdot_",
    agreement: "sopimus_",
    consumerCreditInformation: "kuluttajaluottotiedot_"
};
