import { takeEvery, put, call } from "redux-saga/effects";
import { fetchAccountDocumentIDsSucces } from "../actions/account";
import { fetchDocumentIds } from "../util/api";

import { FETCH_ACCOUNT_DOCUMENTS_TRIGGER } from "../constants/account";

export function* handleDocumentIDsFetch(action) {
    const { token, accountNumber } = action.payload;

    console.log("Fetch document for account ", accountNumber);
    const documents = yield call(fetchDocumentIds, { token, accountNumber });

    console.log("got document Id's", documents);
    yield put(fetchAccountDocumentIDsSucces(documents));
}

export function* watchFetchDdocumentIDs() {
    yield takeEvery(FETCH_ACCOUNT_DOCUMENTS_TRIGGER, handleDocumentIDsFetch);
}
