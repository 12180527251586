import { takeEvery, put, call, select } from "redux-saga/effects";
import { fetchAccountStatements, fetchPaymentPlans } from "../util/api";
import { FETCH_PAYMENT_PLANS_AND_STATEMENTS_TRIGGER } from "../constants/debtStatus";
import { fetchDebtStatusSuccess } from "../actions/debtStatus";

export function* handleFetchPaymentPlansAndStatements() {
    const { accountId, token } = yield select((state) => {
        return {
            token: state.user.token,
            accountId: state.accounts.activeAccount.id
        };
    });

    const statements = yield call(fetchAccountStatements, { token, accountId });
    const paymentPlans = yield call(fetchPaymentPlans, { token, accountId });

    yield put(fetchDebtStatusSuccess({ statements, paymentPlans }));
}

export function* watchFetchPaymentPlansAndStatements() {
    yield takeEvery(
        FETCH_PAYMENT_PLANS_AND_STATEMENTS_TRIGGER,
        handleFetchPaymentPlansAndStatements
    );
}
