import styled from "styled-components/macro";

import { Grid2 } from "../Grid";
import { breakpoints, fontSizes, colors } from "../../constants/styles";

export const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin: 16px 0 24px 0;
    box-shadow: 0 3px 4px 0 #cceff5;
    background-color: rgba(255, 255, 255, 0.85);
    @media ${breakpoints.mobile} {
        height: auto;
    }
`;

export const FooterContent = styled.div`
    margin-top: 24px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;

    @media ${breakpoints.mobile} {
        width: 100%;
        flex-direction: column;
        margin: 24px;
        :last-child {
            align-self: flex-end;
            justify-content: flex-end;
            margin-bottom: 56px;
        }
    }
`;

export const Grid2WithPadding = styled(Grid2)`
    padding-right: 16px;
    height: auto;
    :last-child {
        padding-right: 0;
    }
`;

export const FlexIconRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 16px;
`;

export const FlexIconColumn = styled.div`
    margin-right: 14px;
`;

export const StyledFooterLink = styled.a`
    font-size: ${fontSizes.small};
    color: ${colors.linkBlue};
`;

export const StyledParagraph = styled.p`
    padding: 0;
    margin: 0;
    font-size: ${fontSizes.small};
    line-height: 1.5;
`;

export const StyledPhoneNumber = styled(StyledParagraph)`
    margin: 8px 0 16px 0;
    font-weight: bold;
`;

export const StyledPriceInfo = styled(StyledParagraph)`
    font-style: italic;
`;

export const FooterLink = styled.span`
    display: block;
    margin-bottom: 16px;
    @media ${breakpoints.mobile} {
        margin-left: 35px;
    }
`;

export const FooterLogo = styled.div`
    img {
        width: 200px;
    }
`;
