import * as R from "ramda";
import { FETCH_ACCOUNT_DOCUMENTS_SUCCESS } from "../constants/account";
import { FILE_NAMES } from "../constants/documents";

export const initialState = {
    terms: {},
    agreement: {},
    consumerCreditInformation: {}
};

const getItem = (documentName, documents) => {
    const pattern = new RegExp(documentName);
    const result = R.find(R.propSatisfies((name) => name.search(pattern) !== -1, "filename"))(
        documents
    );

    return result;
};

export function documentsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ACCOUNT_DOCUMENTS_SUCCESS: {
            return {
                terms: getItem(FILE_NAMES.terms, action.payload),
                agreement: getItem(FILE_NAMES.agreement, action.payload),
                consumerCreditInformation: getItem(
                    FILE_NAMES.consumerCreditInformation,
                    action.payload
                )
            };
        }
        default:
            return state;
    }
}
