import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import isBefore from "date-fns/isBefore";
import addDays from "date-fns/addDays";
import startOfDay from "date-fns/startOfDay";
import { FlexColumn, FlexRow, Grid2, GridContainer, GridContent } from "../../components/Grid";
import { PageTitleBox } from "../../components/PageTitleBox";
import { MainContent } from "../../components/MainContent";
import { BoxContainer } from "../../components/BoxContainer";
import { TitleContainer } from "../../components/TitleContainer";
import { SmallTitle } from "../../components/Fonts/SmallTitle";
import { BasicText } from "../../components/Fonts/BasicText";
import { SmallText } from "../../components/Fonts/SmallText";
import { DEFAULT_FORMAT_DATE } from "../../constants/dateTime";
import { DateSelector } from "../../components/DateSelector";
import { CommonButton } from "../../components/CommonButton";
import { CenteredButtonContainer } from "../../components/CenteredButtonContainer";
import { fetchRemainingDebtTrigger } from "../../actions/remainingDebt";
import styled from "styled-components/macro";
import { Icon } from "../../components/Icon";
import { breakpoints, colors } from "../../constants/styles";
import { ContentItem } from "../../components/ContentItem";
import { Currency, getCurrency } from "../../components/Currency";
import { PageTitleWithRightContent } from "../../components/PageTitleWithRightContent";
import { selectCustomerBasicInfoAndAddress } from "../../selectors/customer";
import { getFetchPdfStatus } from "../../selectors/fetchPdf";
import { selectToken } from "../../selectors/token";
import { fetchPdfDocumentTrigger, fetchPdfDocumentReset } from "../../actions/fetchPdf";
import { messages } from "./messages";

const DateSelectorContainer = styled.div`
    display: flex;
    margin: 0 16px 0 0;
    padding: 0;
    @media ${breakpoints.mobile} {
        margin: 0 16px 16px 0;
    }
    @media ${breakpoints.tablet} {
        margin: 0 16px 16px 0;
    }
`;

const DateSelectorIconContainer = styled.div`
    display: flex;
    padding: 0;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right: 1px solid #979797;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    background-color: #fff;
    cursor: pointer;
    margin: 0 0 0 -27px;
    position: relative;
    z-index: 3000;
    pointer-events: none;
`;

const InfoItems = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px 0 0 0;
`;

const InfoItemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
`;

const InfoItemCircle = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 2px;
    display: flex;
    background-color: ${colors.ligthGreen};
`;

const InfoItemCircleContainer = styled.div`
    padding: 8px 8px 0 8px;
`;

const InfoItemContent = styled.div``;

export function PayNowCalculator() {
    const dispatch = useDispatch();
    const now = startOfDay(new Date());
    const [date, setDate] = useState(now);
    const lastDay = addDays(startOfDay(now), 28);

    const { remainingDebt, activeAccountInfo } = useSelector((state) => {
        console.log("state remaining debt", state.remainingDebt);
        return {
            activeAccountInfo: state.accounts.activeAccountInfo,
            remainingDebt: state.remainingDebt.remainingDebt
        };
    });
    const { customerBasicInfo, address } = useSelector(selectCustomerBasicInfoAndAddress) || {};
    const { loading } = useSelector(getFetchPdfStatus);
    const token = useSelector(selectToken || "");
    const { remainingPrincipal } = activeAccountInfo;
    const totalRemainingDebt =
        remainingDebt && remainingDebt.remainingDebts ? remainingDebt.remainingDebts : 0;
    const totalExpenses = Math.abs(totalRemainingDebt - remainingPrincipal);
    const totalPrincipal = remainingPrincipal;

    const { formatMessage: f } = useIntl();

    useEffect(() => {
        dispatch(fetchRemainingDebtTrigger());
        dispatch(fetchPdfDocumentReset());
    }, [dispatch]);

    const handleCreatePdf = () => {
        const fee = getCurrency(remainingDebt.items[0].serviceFee);

        const data = {
            date: format(now, DEFAULT_FORMAT_DATE),
            repaymentDate: format(date, DEFAULT_FORMAT_DATE),
            customerNumber: customerBasicInfo.customerId,
            customerName: `${customerBasicInfo.givenName} ${customerBasicInfo.surName}`,
            address: address.streetAddress,
            postCode: address.zipCode,
            city: address.city,
            remainingDebt: getCurrency(remainingPrincipal),
            interest: getCurrency(totalExpenses),
            fee,
            ocrNumber: remainingDebt.ocrNumber,
            sum: getCurrency(totalRemainingDebt),
            recipientAccountNumber: f(messages.calculator.accountcontent),
            params: {
                source: "SVEA-PdfCalculation"
            }
        };

        const params = {
            token,
            endpoint: "calculation"
        };
        dispatch(fetchPdfDocumentTrigger(data, params));
    };

    if (!remainingDebt) return null;

    return (
        <FlexColumn>
            <PageTitleBox>
                <PageTitleWithRightContent title={f(messages.pagetitle)}>
                    <BasicText>{`${f(messages.customernumber)} ${
                        customerBasicInfo.customerId
                    }`}</BasicText>
                </PageTitleWithRightContent>
            </PageTitleBox>
            <GridContainer>
                <Grid2>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <TitleContainer>
                                    <SmallTitle>{f(messages.calculator.subtitle)}</SmallTitle>
                                </TitleContainer>
                                <FlexColumn>
                                    <ContentItem title={f(messages.calculator.chooseday)}>
                                        <FlexRow>
                                            <DateSelectorContainer>
                                                <DateSelector
                                                    selected={date}
                                                    onChange={(date) => setDate(date)}
                                                    dateFormat={DEFAULT_FORMAT_DATE}
                                                    onFilterDate={(date) => {
                                                        return (
                                                            isBefore(now, addDays(date, 1)) &&
                                                            isBefore(date, lastDay)
                                                        );
                                                    }}
                                                />
                                                <DateSelectorIconContainer>
                                                    <Icon
                                                        icon={["fa", "calendar"]}
                                                        size="1x"
                                                        color={colors.gray}
                                                    />
                                                </DateSelectorIconContainer>
                                            </DateSelectorContainer>
                                            <CommonButton
                                                onClick={() => {
                                                    dispatch(fetchRemainingDebtTrigger(date));
                                                }}>
                                                {f(messages.calculator.button)}
                                            </CommonButton>
                                        </FlexRow>
                                    </ContentItem>
                                    <ContentItem title={f(messages.calculator.chosendate)}>
                                        {format(date, DEFAULT_FORMAT_DATE)}
                                    </ContentItem>
                                    <ContentItem title={f(messages.calculator.accounttitle)}>
                                        {f(messages.calculator.accountcontent)}
                                    </ContentItem>
                                    <ContentItem title={f(messages.calculator.receivertitle)}>
                                        {f(messages.calculator.companyname)}
                                    </ContentItem>
                                    <ContentItem title={f(messages.calculator.receivernotetitle)}>
                                        {remainingDebt.ocrNumber}
                                    </ContentItem>
                                    <ContentItem title={f(messages.calculator.sumtitle)}>
                                        <Currency>{remainingDebt.remainingDebts}</Currency> <br />
                                        <SmallText>
                                            <FormattedMessage
                                                {...messages.calculator.sumcontent}
                                                values={{
                                                    totalPrincipal: (
                                                        <Currency>{totalPrincipal}</Currency>
                                                    ),
                                                    totalExpenses: (
                                                        <Currency>{totalExpenses}</Currency>
                                                    )
                                                }}
                                            />
                                        </SmallText>
                                    </ContentItem>
                                    <CenteredButtonContainer>
                                        <CommonButton
                                            onClick={() => handleCreatePdf()}
                                            disabled={loading}>
                                            {loading && (
                                                <Icon
                                                    icon={["fas", "circle-notch"]}
                                                    color={colors.white}
                                                    spin
                                                />
                                            )}
                                            {f(messages.calculator.calculationbutton)}
                                        </CommonButton>
                                    </CenteredButtonContainer>
                                </FlexColumn>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid2>
                <Grid2>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <TitleContainer>
                                    <SmallTitle>{f(messages.calculatorinfo.title)}</SmallTitle>
                                </TitleContainer>
                                <BasicText>{f(messages.calculatorinfo.listinfo)}</BasicText>
                                <InfoItems>
                                    <InfoItemContainer>
                                        <InfoItemCircleContainer>
                                            <InfoItemCircle />
                                        </InfoItemCircleContainer>
                                        <InfoItemContent>
                                            {f(messages.calculatorinfo.listitem1)}
                                        </InfoItemContent>
                                    </InfoItemContainer>
                                    <InfoItemContainer>
                                        <InfoItemCircleContainer>
                                            <InfoItemCircle />
                                        </InfoItemCircleContainer>
                                        <InfoItemContent>
                                            {f(messages.calculatorinfo.listitem2)}
                                        </InfoItemContent>
                                    </InfoItemContainer>
                                    <InfoItemContainer>
                                        <InfoItemCircleContainer>
                                            <InfoItemCircle />
                                        </InfoItemCircleContainer>
                                        <InfoItemContent>
                                            {f(
                                                messages.calculatorinfo.listitem3,
                                                messages.calculatorinfo.listitem3.values
                                            )}
                                        </InfoItemContent>
                                    </InfoItemContainer>
                                    <InfoItemContainer>
                                        <InfoItemCircleContainer>
                                            <InfoItemCircle />
                                        </InfoItemCircleContainer>
                                        <InfoItemContent>
                                            {f(
                                                messages.calculatorinfo.listitem4,
                                                messages.calculatorinfo.listitem4.values
                                            )}
                                        </InfoItemContent>
                                    </InfoItemContainer>
                                </InfoItems>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid2>
            </GridContainer>
        </FlexColumn>
    );
}
