export const FETCH_ACCOUNT_TRIGGER = "FETCH/ACCOUNT/TRIGGER";
export const FETCH_ACCOUNT_SUCCESS = "FETCH/ACCOUNT/SUCCESS";
export const FETCH_ACCOUNT_DOCUMENTS_TRIGGER = "FETCH/ACCOUNT/DOCUMENTS/TRIGGER";
export const FETCH_ACCOUNT_DOCUMENTS_SUCCESS = "FETCH/ACCOUNT/DOCUMENTS/SUCCESS";
export const FETCH_ACCOUNT_DOCUMENTS_ERROR = "FETCH/ACCOUNT/DOCUMENTS/ERROR";
export const FETCH_ACCOUNT_STATEMENTS_TRIGGER = "FETCH/ACCOUNT/STATEMENTS/TRIGGER";
export const FETCH_ACCOUNT_STATEMENTS_SUCCESS = "FETCH/ACCOUNT/STATEMENTS/SUCCESS";
export const SELECT_ACCOUNT = "ACCOUNT/SELECT";
export const SET_SELECTED_ACCOUNT = "ACCOUNT/SET_SELECTED_ACCOUNT";
export const SET_SELECTED_ACCOUNT_INFO = "ACCOUNT/SET_SELECTED_ACCOUNT_INFO";
export const SAVE_BILLING_SETTINGS_TRIGGER = "ACCOUNT/SAVE_BILLING_SETTINGS/TRIGGER";
export const SAVE_BILLING_SETTINGS_SUCCESS = "ACCOUNT/SAVE_BILLING_SETTINGS/SUCCESS";
export const SAVE_BILLING_SETTINGS_ERROR = "ACCOUNT/SAVE_BILLING_SETTINGS/ERROR";
export const SAVE_BILLING_SETTINGS_RESET = "ACCOUNT/SAVE_BILLING_SETTINGS/INIT";
