import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import * as R from "ramda";
import { validateToken } from "../actions/user";
import { Loader } from "./Loader";
import { NoLoanPage } from "../pages/NoLoanPage";

const hasAccount = R.has("accountList");

const selector = createSelector(
    (state) => state.user.isValidToken,
    (state) => state.user.initialized,
    (state) => state.user.customer,
    (isValidToken, initialized, customer) => {
        return {
            isValidToken: isValidToken,
            initialized,
            noAccount: !hasAccount(customer)
        };
    }
);

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isValidToken, initialized, noAccount } = useSelector(selector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(validateToken());
    }, [dispatch]);

    console.log("initialized", isValidToken);

    if (!initialized) {
        return <Loader />;
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!initialized) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                } else {
                    return noAccount ? <NoLoanPage /> : <Component {...props} />;
                }
            }}
        />
    );
};
