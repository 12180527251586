import React, { useState } from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";
import { ModalDialog } from "../ModalDialog";
import { fontSizes } from "../../constants/styles";
import { NewsModalContent } from "./NewsModalContent";
import { CommonButton } from "../CommonButton";
import { CenteredButtonContainer } from "../CenteredButtonContainer";

import { messages } from "./messages";

const NewsItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`;

const ItemDate = styled.div`
    font-size: ${fontSizes.small};
    line-height: 1.14;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
`;

const ItemHeadline = styled.div`
    font-size: ${fontSizes.normal};
    font-weight: bold;
    line-height: 1.5;
`;

const ItemIngress = styled.div`
    font-size: ${fontSizes.normal};
    line-height: 1.5;
`;

export const NewsItem = (props) => {
    const { headline, ingress, createdDate, content } = props;
    const [modalOpen, toggleModalOpen] = useState(false);

    return (
        <NewsItemContainer>
            <ItemDate>{createdDate}</ItemDate>
            <ItemHeadline>{headline}</ItemHeadline>
            <ItemIngress>
                <ReactMarkdown source={ingress} />
            </ItemIngress>
            <CenteredButtonContainer marginBottom="12px">
                <CommonButton onClick={() => toggleModalOpen(!modalOpen)}>
                    <FormattedMessage {...messages.more} />
                </CommonButton>
            </CenteredButtonContainer>
            <ModalDialog
                open={modalOpen}
                closeDialog={() => toggleModalOpen(false)}
                headline={headline}>
                <NewsModalContent createdDate={createdDate} content={content} />
            </ModalDialog>
        </NewsItemContainer>
    );
};
