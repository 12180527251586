import {
    getAccessTokenMock,
    getAccountMock,
    getDocumentIdsMock,
    getAccountStatementsMock,
    getCustomerInfoMock,
    getPaymentPlansMock,
    getVerifyTokenMock,
    getDocumentMock,
    getRemainingDebtMock,
    getRenewTokenMock,
    setStatementChannelMock,
    updateUserInfoMock,
    getSpecialPaymentAgreementsMock,
    setSpecialPaymentAgreementsMock
} from "./mocks";

const leandevApiUrl = process.env.REACT_APP_LEANDEV_API_URL;
const frendsApiUrlPath = `${process.env.REACT_APP_FRENDS_BASE_API_URL}/api/online/v1/ostosrahasvea`;
const fake = parseInt(process.env.REACT_APP_MOCK);

const API_VERSION = "v4";

console.log("fake api requests?", fake);

export async function getToken(data) {
    const url = `${leandevApiUrl.replace("/lfp", "")}/login`;
    const method = "POST";

    if (fake) {
        return getAccessTokenMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            authorizationCode: data.authorizationCode
        })
    });

    if (response.status === 403 || response.status === 204) {
        return null;
    }

    return await response.json();
}

export async function verifyToken(data) {
    const url = `${leandevApiUrl}/api/auth/v1/tokens/verify`;
    const method = "POST";

    if (fake) {
        return getVerifyTokenMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "X-Auth-Token": data.token
        }
    });

    const status = response.status;

    console.log("got verify response status", status);

    return status === 200;
}

export async function renewToken(data) {
    const url = `${leandevApiUrl}/api/auth/v1/tokens/renew`;
    const method = "POST";

    if (fake) {
        return getRenewTokenMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "X-Auth-Token": process.env.REACT_APP_AUTH_TOKEN
        },
        body: JSON.stringify({
            accessToken: data.token
        })
    });

    const status = response.status;
    const body = await response.json();

    if (status !== 200) {
        return null;
    } else {
        return body;
    }
}

export async function fetchCustomer(data) {
    const url = `${leandevApiUrl}/api/customer/${API_VERSION}/privateCustomers/me`;
    const method = "GET";

    if (fake) {
        return getCustomerInfoMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token
        }
    });

    if (response.status === 403 || response.status === 401) {
        return false;
    }

    return await response.json();
}

export async function fetchAccount(data) {
    const url = `${leandevApiUrl}/api/loan/${API_VERSION}/accounts/${data.accountId}`;
    const method = "GET";

    if (fake) {
        return getAccountMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token
        }
    });

    return await response.json();
}

export async function fetchDocumentIds(data) {
    const url = `${leandevApiUrl}/api/storage/v2/documents`;
    const method = "POST";

    if (fake) {
        return getDocumentIdsMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "x-auth-token": data.token
        },
        body: JSON.stringify({
            "metadata.accountNumber": data.accountId
        })
    });

    return await response.json();
}

export async function fetchAccountStatements(data) {
    const url = `${leandevApiUrl}/api/loan/${API_VERSION}/accounts/${data.accountId}/statements`;
    const method = "GET";

    if (fake) {
        return getAccountStatementsMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token
        }
    });

    return await response.json();
}

export async function fetchPaymentPlans(data) {
    const url = `${leandevApiUrl}/api/loan/${API_VERSION}/accounts/${data.accountId}/paymentPlans`;
    const method = "GET";

    if (fake) {
        return getPaymentPlansMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token
        }
    });

    return await response.json();
}

//* Fetch payment free months
export async function fetchSpecialPaymentAgreements(data) {
    const url = `${leandevApiUrl}/api/loan/${API_VERSION}/accounts/${data.accountId}/specialPaymentAgreements`;
    const method = "GET";

    if (fake) {
        return getSpecialPaymentAgreementsMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token
        }
    });

    return await response.json();
}

//* Set payment free month
export async function setSpecialPaymentAgreements(data) {
    const url = `${leandevApiUrl}/api/loan/${API_VERSION}/accounts/${data.accountId}/specialPaymentAgreements`;
    const method = "POST";

    const specialPaymentAgreement = {
        paymentAgreementType: data.paymentAgreementType,
        startDate: data.startDate,
        endDate: data.endDate,
        administrativeFee: 0
    };

    if (fake) {
        return setSpecialPaymentAgreementsMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token
        },
        body: JSON.stringify(specialPaymentAgreement)
    });

    const { status } = response;

    console.log("got statement channel response status", status);

    return status === 200;
}

export async function fetchDocument(data) {
    const url = `${leandevApiUrl}/api/storage/v2/documents/${data.documentId}/file`;
    const method = "GET";

    if (fake) {
        return getDocumentMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/octet-stream",
            "X-Auth-Token": data.token
        }
    });

    const blob = await response.blob();

    const blobData = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobData;
    link.download = "document.pdf";
    link.click();
}

export async function fetchRemainingDebt(data) {
    const url = `${leandevApiUrl}/api/loan/${API_VERSION}/accounts/${data.accountId}/remainingDebt?preSettleDate=${data.date}`;
    const method = "GET";

    if (fake) {
        return getRemainingDebtMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token
        }
    });

    return await response.json();
}

export async function setStatementChannel(data) {
    const url = `${leandevApiUrl}/api/loan/${API_VERSION}/accounts/${data.accountId}/statementChannel`;
    const method = "PUT";

    //* Note: statementChannel, token, accountId
    if (fake) {
        return setStatementChannelMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token
        },
        body: JSON.stringify({
            statementChannel: data.statementChannel
        })
    });

    const { status } = response;

    console.log("got statement channel response status", status);

    return status === 200;
}

export async function updateUserInfo(data) {
    const url = `${leandevApiUrl}/api/customer/${API_VERSION}/privateCustomers/me`;
    const method = "PATCH";

    if (fake) {
        return updateUserInfoMock(url, method, data);
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token
        },
        body: JSON.stringify({
            customerBasicInfo: data.customerBasicInfo
        })
    });
    const { status } = response;

    return status === 200;
}

export async function sendFeedback(data) {
    const method = "POST";
    const { token, customerName, customerEmail, customerNumber, answerRequired, message } = data;
    const isDev = parseInt(process.env.REACT_APP_MOCK);

    const url = isDev
        ? `${frendsApiUrlPath}/feedback?localDev=true`
        : `${frendsApiUrlPath}/feedback`;

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": token
        },
        body: JSON.stringify({
            customerName,
            customerEmail,
            customerNumber,
            answerRequired,
            message
        })
    });

    const { status } = response;
    return status === 200;
}

export async function makeCalculationPdf(params, data) {
    const method = "POST";
    const isDev = parseInt(process.env.REACT_APP_MOCK);
    const { token, endpoint } = params;

    const url = isDev
        ? `${frendsApiUrlPath}/${endpoint}?localDev=true`
        : `${frendsApiUrlPath}/${endpoint}`;

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/pdf",
            "X-Auth-Token": token
        },
        body: JSON.stringify({ ...data })
    });

    const blob = await response.blob();

    const blobData = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobData;
    link.target = "_blank";
    link.click();
    return response;
}
