export const STATEMENT_STATUSES = {
    COLLECTION: "COLLECTION",
    CURING: "CURING",
    OVERDUE: "OVERDUE",
    PAIDINFULL: "PAIDINFULL",
    PARTIALLYPAID: "PARTIALLYPAID",
    PENDING: "PENDING",
    UNPAID: "UNPAID",
    NOTGENERATED: "NOT_GENERATED",
    GENERATED: "GENERATED",
    CREDIT: "CREDIT",
    CURINGCREDIT: "CURING/CREDIT",
    UNPDAID: "UNPDAID"
};

// avoin, laskuttamaton, maksettu, erääntynyt
export const TRANSLATED_STATEMENT_STATUSES = {};
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.COLLECTION] = msg("statement.status.collection");
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.CURING] = msg("statement.status.curing");
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.OVERDUE] = msg("statement.status.overdue");
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.PAIDINFULL] = msg("statement.status.paidinfull");
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.PARTIALLYPAID] = msg(
    "statement.status.partiallypaid"
);
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.PENDING] = msg("statement.status.pending");
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.UNPAID] = msg("statement.status.unpaid");
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.UNPDAID] = msg("statement.status.unpaid");
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.NOTGENERATED] = msg(
    "statement.status.notgenerated"
);
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.GENERATED] = msg("statement.status.generated");
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.CREDIT] = msg("statement.status.credit");
TRANSLATED_STATEMENT_STATUSES[STATEMENT_STATUSES.CURINGCREDIT] = msg(
    "statement.status.curingcredit"
);

function msg(id) {
    return { id, defaultMessage: "[Translation missing]" };
}
