import * as R from "ramda";
import { FETCH_PAYMENT_PLANS_AND_STATEMENTS_SUCCESS } from "../constants/debtStatus";
import { format, parseISO } from "date-fns";
import { DEFAULT_FORMAT_DATE } from "../constants/dateTime";

export const initialState = {
    paymentPlans: [],
    statements: [],
    entities: [],
    latestStatement: {},
    firstStatement: {}
};

const hasStatement = R.has("statement");

const setEntities = (paymentPlans, statements) => {
    const entities = paymentPlans;
    const tenorCount = entities.length;

    statements.forEach((statement) => {
        const sNum = statement.seriesNum ? parseInt(statement.seriesNum) : null;
        if (sNum) {
            const paymentPlanItem = R.find(R.propEq("seriesNum", sNum))(paymentPlans);
            if (paymentPlanItem) {
                paymentPlanItem.statement = {
                    ...statement,
                    dueDate: format(parseISO(statement.dueDate), DEFAULT_FORMAT_DATE)
                };
            }
        }
    });

    return entities
        .sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1))
        .map((item, index) => {
            const title = `${index + 1}/${tenorCount}`;
            const documentId = hasStatement(item) ? item.statement.documentId : undefined;

            return {
                ...item,
                title,
                dueDate: format(parseISO(item.dueDate), DEFAULT_FORMAT_DATE),
                documentId
            };
        });
};

const getFirstStatementId = (statements) =>
    statements[statements.length - 1].seriesNum
        ? statements[statements.length - 1].seriesNum
        : null;

const getLastStatementId = (statements) => {
    if (statements[0].seriesNum) return statements[0].seriesNum;
    return statements[1].seriesNum;
};

export function debtStatusReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PAYMENT_PLANS_AND_STATEMENTS_SUCCESS: {
            console.log("got payment plans and statements", action);
            const entities = setEntities(action.payload.paymentPlans, action.payload.statements);
            const firstStatementId = (action.payload.statements.length > 0) && getFirstStatementId(action.payload.statements);
            const lastStatementId = (action.payload.statements.length > 0) && getLastStatementId(action.payload.statements);

            const firstStatement = R.find(R.propEq("seriesNum", firstStatementId))(
                action.payload.statements
            );
            const latestStatement = R.find(R.propEq("seriesNum", lastStatementId))(
                action.payload.statements
            );

            return {
                paymentPlans: action.payload.paymentPlans,
                statements: action.payload.statements,
                entities,
                firstStatement,
                latestStatement
            };
        }
        default:
            return state;
    }
}
