import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { breakpoints } from "../constants/styles";

const StyledLinkContainer = styled(Link)`
    padding: 24px 16px 12px 16px;
    text-decoration: none;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #444444;
    ${(props) =>
        props.isactive === "true" &&
        `> span {
            text-shadow: 0px 0px 1px black;
            border-bottom: 1px solid #ff5711;
        }`}

    &:hover > span {
        text-shadow: 0px 0px 1px black;
        border-bottom: 1px solid #ff5711;
    }
    @media ${breakpoints.tablet} {
        padding: 8px 8px 18px 8px;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }
`;

const StyledButton = styled.button`
    background: none !important;
    border: none;
    padding: 0 !important;
    margin: 3px 8px;
    line-height: 1.5;
    font-family: Roboto;
    font-size: 16px;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
`;

export const LinkContainer = (props) => {
    return <StyledLinkContainer isactive={useRouteMatch(props.to) && "true"} {...props} />;
};

export const ButtonLink = (props) => {
    return <StyledButton {...props} />;
};
