import React from "react";
import styled from "styled-components/macro";

export const StyledRaisedButton = styled.button`
    border-radius: 4px;
    border: 0;
    box-shadow: 0 3px 4px 0 #cceff5;
    background-color: #ffffff;
    cursor: pointer;
    padding: 24px;
    width: 100%;
    height: 100%;
    &:hover {
        box-shadow: 0 2px 2px 0 #00aece;
    }
    &:active {
        box-shadow: 0 2px 2px 0 #0094af;
        background-color: #cceff5;
        border: 0;
    }
`;

export function RaisedButton(props) {
    const { onClick, children, disabled } = props;

    return (
        <StyledRaisedButton onClick={onClick} disabled={disabled}>
            {children}
        </StyledRaisedButton>
    );
}
