import React from "react";
import styled from "styled-components/macro";
import { fontSizes, colors, breakpoints } from "../../constants/styles";

const StyledButton = styled.button`
    cursor: pointer;
    background-color: #00aece;
    border-radius: 4px;
    padding: ${(props) => (props.padding ? props.padding : `1px 21px 2px`)};
    height: 31px;
    font-size: ${fontSizes.normal};
    color: ${colors.white};
    font-weight: bold;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    &:hover {
        background-color: #0094af;
    }
    &:active {
        background-color: #0094af;
    }
    @media ${breakpoints.mobile} {
        min-height: 31px;
        height: auto;
    }
`;

export const CommonButton = ({ onClick, children, padding, disabled }) => {
    return (
        <StyledButton onClick={onClick} padding={padding} disabled={disabled}>
            {children}
        </StyledButton>
    );
};
