import React from "react";
import { defineMessages } from "react-intl";
import { TRANSLATED_STATEMENT_STATUSES } from "../../constants/statementStatus";
import { BasicTextP } from "../../components/Fonts/BasicText";

export const messages = defineMessages({
    pagetitle: {
        id: "page.loan.pagetitle",
        defaultMessage: "[Translation missing]"
    },
    customernumber: {
        id: "customer.number.short",
        defaultMessage: "[Translation missing]"
    },
    statementsTableColumns: {
        number: {
            id: "invoicestable.column.number",
            defaultMessage: "[Translation missing]"
        },
        title: {
            id: "invoicestable.column.title",
            defaultMessage: "[Translation missing]"
        },
        status: {
            id: "invoicestable.column.status",
            defaultMessage: "[Translation missing]"
        },
        duedate: {
            id: "invoicestable.column.duedate",
            defaultMessage: "[Translation missing]"
        },
        unpaidamount: {
            id: "invoicestable.column.unpaidamount",
            defaultMessage: "[Translation missing]"
        },
        total: {
            id: "invoicestable.column.total",
            defaultMessage: "[Translation missing]"
        },
        statementtitle: {
            id: "invoicestable.statement.title",
            defaultMessage: "[Translation missing]"
        }
    },
    info: {
        subtitle: {
            id: "page.loan.info.subtitle",
            defaultMessage: "[Translation missing]"
        },
        remainingtoday: {
            id: "page.loan.info.remaining.today",
            defaultMessage: "[Translation missing]"
        },
        remaininginfo: {
            id: "page.loan.info.remaining.info",
            defaultMessage: "[Translation missing]",
            values: {
                p: (value) => <BasicTextP>{value}</BasicTextP>
            }
        },
        modalsummary: {
            id: "page.loan.modal.summary",
            defaultMessage: "[Translation missing]"
        },
        modalcontracts: {
            id: "page.loan.modal.contracts",
            defaultMessage: "[Translation missing]"
        }
    },
    changepaymentinfobutton: {
        id: "page.loan.paymentinfo.change.button",
        defaultMessage: "[Translation missing]"
    },
    statementstable: {
        id: "page.loan.statements.tablename",
        defaultMessage: "[Translation missing]"
    },
    statementssettings: {
        id: "page.loan.statements.settings",
        defaultMessage: "[Translation missing]"
    },
    archivetable: {
        id: "page.loan.archive.tablename",
        defaultMessage: "[Translation missing]"
    },
    paymentinfobutton: {
        id: "page.loan.paymentinfo.button",
        defaultMessage: "[Translation missing]"
    },
    calculator: {
        subtitle: {
            id: "page.loan.calculator.subtitle",
            defaultMessage: "[Translation missing]"
        },
        chooseday: {
            id: "page.loan.calculator.chooseday",
            defaultMessage: "[Translation missing]"
        },
        button: {
            id: "page.loan.calculator.button",
            defaultMessage: "[Translation missing]"
        },
        chosendate: {
            id: "page.loan.calculator.chosendate",
            defaultMessage: "[Translation missing]"
        },
        accounttitle: {
            id: "page.loan.calculator.bankaccount.title",
            defaultMessage: "[Translation missing]"
        },
        accountcontent: {
            id: "page.loan.calculator.bankaccount.content",
            defaultMessage: "[Translation missing]"
        },
        receivertitle: {
            id: "page.loan.calculator.receiver.title",
            defaultMessage: "[Translation missing]"
        },
        companyname: {
            id: "companyinfo.name",
            defaultMessage: "[Translation missing]"
        },
        receivernotetitle: {
            id: "page.loan.calculator.receivernote.title",
            defaultMessage: "[Translation missing]"
        },
        sumtitle: {
            id: "page.loan.calculator.sum.title",
            defaultMessage: "[Translation missing]"
        },
        sumcontent: {
            id: "page.loan.calculator.sum.content",
            defaultMessage: "[Translation missing]"
        },
        calculationbutton: {
            id: "page.loan.calculator.calculation.button",
            defaultMessage: "[Translation missing]"
        }
    },
    calculatorinfo: {
        title: {
            id: "page.loan.calculator.info.subtitle",
            defaultMessage: "[Translation missing]"
        },
        listinfo: {
            id: "page.loan.calculator.info.listinfo",
            defaultMessage: "[Translation missing]"
        },
        listitem1: {
            id: "page.loan.calculator.info.listitem1",
            defaultMessage: "[Translation missing]"
        },
        listitem2: {
            id: "page.loan.calculator.info.listitem2",
            defaultMessage: "[Translation missing]"
        },
        listitem3: {
            id: "page.loan.calculator.info.listitem3",
            defaultMessage: "[Translation missing]",
            values: {
                adobelink: (value) => (
                    <a
                        href="https://get.adobe.com/reader"
                        target="_blank"
                        rel="noopener noreferrer">
                        {value}
                    </a>
                )
            }
        },
        listitem4: {
            id: "page.loan.calculator.info.listitem4",
            defaultMessage: "[Translation missing]",
            values: {
                mailto: (value) => <a href={`mailto: ${value}`}>{value}</a>,
                newrow: <br />
            }
        }
    },
    ...TRANSLATED_STATEMENT_STATUSES
});
