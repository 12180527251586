import { format, parseISO } from "date-fns";
import { DEFAULT_FORMAT_DATE } from "../constants/dateTime";
import { NEWS_INGRESS_MAX_LENGTH } from "../constants/contentful";

export const getNewsSelector = (state) => {
    const items = state.contentful.news;

    if (!items) {
        return [];
    }

    items.forEach((item) => {
        const { fields } = item;

        fields.createdDate = format(parseISO(fields.publicationDate), DEFAULT_FORMAT_DATE);

        const sliced = fields.content.slice(0, NEWS_INGRESS_MAX_LENGTH);
        if (sliced.endsWith(" ")) {
            fields.ingress = `${sliced}...`;
        } else {
            const lastSpace = sliced.lastIndexOf(" ");
            const lastSpaceSliced = sliced.slice(0, lastSpace + 1);
            fields.ingress = `${lastSpaceSliced}...`;
        }
    });
    return items;
};
