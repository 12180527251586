import React from "react";
import { RaisedButton } from "./RaisedButton";
import { BoxContent } from "./BoxContent";

export const InstallmentsButton = ({ onClick, children, disabled }) => {
    return (
        <RaisedButton onClick={onClick} disabled={disabled}>
            <BoxContent center>{children}</BoxContent>
        </RaisedButton>
    );
};
