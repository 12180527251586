import React from "react";
import styled from "styled-components/macro";
import { breakpoints } from "../constants/styles";

const AutenticationContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
`;

const LogOutButton = styled.button`
    cursor: pointer;

    border-radius: 4px;
    box-shadow: 0 3px 4px 0 #cceff5;
    background-color: #ffffff;
    &:hover {
        box-shadow: 0 2px 2px 0 #86c463;
    }
    &:active {
        box-shadow: 0 2px 2px 0 #86c463;
        background-color: #f5fff2;
    }
    @media ${breakpoints.tablet} {
        margin-top: 14px;
    }
    @media ${breakpoints.mobile} {
        background-color: rgba(255, 255, 255, 0);
        box-shadow: none;
        padding: 0px;
        &:hover {
            box-shadow: none;
        }
        &:active {
            box-shadow: none;
            background-color: transparent;
        }
    }
`;
const Autenticate = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #444444;

    @media ${breakpoints.mobile} {
        display: flex;
        padding: ${(props) => (props.mobilePadding ? "24px 18px 16px 16px" : "0")};
    }
`;

export const AutenticationText = styled.span`
    @media ${breakpoints.mobile} {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #444444;
        order: 1;
        &:hover {
            text-decoration: underline;
        }
        &:active {
            text-decoration: underline;
        }
    }
`;

export const AutenticationImage = styled.span`
    margin-right: 8px;
    @media ${breakpoints.mobile} {
        margin-right: 0px;
        margin-left: 8px;
        order: 2;
    }
`;

export const AutenticationButton = ({ onClick, mobilePadding, children }) => {
    return (
        <AutenticationContainer>
            <LogOutButton onClick={onClick}>
                <Autenticate mobilePadding={mobilePadding}>{children}</Autenticate>
            </LogOutButton>
        </AutenticationContainer>
    );
};
