import React, { useState } from "react";
import Collapsible from "react-collapsible";

import { CollapsiblePanelTitle } from "../../components/CollapsiblePanelTitle";
import { TableContainer, MobileTableContainer } from "./styled";
import { ContentContainer } from "./InvoicesArchive.styled";
import { InvoicesTable, MobileInvoicesTable } from "../../components/InvoicesTable";

export const InvoicesArchive = ({
    title,
    tableData,
    token,
    noButton,
    pdfData,
    pdfHeaders,
    pdfParams,
    pdfDispatch,
    loading
}) => {
    const [panelOpen, setPanelOpen] = useState(false);

    return (
        <Collapsible
            trigger={<CollapsiblePanelTitle title={title} isOpen={panelOpen} />}
            onOpening={() => setPanelOpen(true)}
            onClosing={() => setPanelOpen(false)}
            overflowWhenOpen={"visible"}>
            <ContentContainer>
                <TableContainer>
                    <InvoicesTable
                        tableData={tableData}
                        token={token}
                        noButton={noButton}
                        pdfData={pdfData}
                        pdfHeaders={pdfHeaders}
                        pdfParams={pdfParams}
                        pdfDispatch={pdfDispatch}
                        loading={loading}
                    />
                </TableContainer>
                <MobileTableContainer>
                    <MobileInvoicesTable
                        tableData={tableData}
                        token={token}
                        noButton={noButton}
                        pdfData={pdfData}
                        pdfHeaders={pdfHeaders}
                        pdfParams={pdfParams}
                        pdfDispatch={pdfDispatch}
                        loading={loading}
                    />
                </MobileTableContainer>
            </ContentContainer>
        </Collapsible>
    );
};
