import React from "react";
import styled from "styled-components";
import { colors } from "../constants/styles";

const Container = styled.div`
    background-color: ${colors.lightGray};
    border: 1px solid #000;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    margin: 8px;
    padding: 0;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 3px 4px 0 #cceff5;
    &:last-of-type {
        margin: 0;
    }
`;

export function BoxContainer({ children }) {
    return <Container>{children}</Container>;
}
