import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import * as R from "ramda";

import { history } from "./history";
import { store } from "./store";
import { Layout } from "./Layout";
import { FrontPage } from "./pages/FrontPage";
import { LoanPage } from "./pages/LoanPage";
import { UserPage } from "./pages/UserPage";
import { ContactPage } from "./pages/ContactPage";
import { StartPage } from "./pages/StartPage";
import { LoginPage } from "./pages/LoginPage";
import { ChangePage } from "./pages/ChangePage";
import { PrivateRoute } from "./components/PrivateRoute";
import { GlobalStyle } from "./globalStyles";
import { ErrorPage } from "./pages/ErrorPage";
import { LogOutPage } from "./pages/LogOutPage";
import { NoLoanPage } from "./pages/NoLoanPage";
import { translationsTrigger } from "./actions/ui";
import { selectMessages } from "./selectors/ui";
let messages = {};

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        if (R.isEmpty(messages)) {
            dispatch(translationsTrigger({ langId: "fi", portal: "Svea" }));
        }
    }, [dispatch]);

    messages = useSelector(selectMessages);

    return (
        <Provider store={store}>
            <IntlProvider
                onError={() => null}
                locale={process.env.REACT_APP_LOCALE}
                messages={messages}>
                <Router history={history}>
                    <Layout>
                        <Switch>
                            <Route exact path="/" component={StartPage} />
                            <Route exact path="/login" component={LoginPage} />
                            <Route exact path="/logout" component={LogOutPage} />
                            <Route exact path="/error" component={ErrorPage} />
                            <Route exact path="/contact" component={ContactPage} />
                            <Route exact path="/no-loan" component={NoLoanPage} />
                            <PrivateRoute exact path="/front" component={FrontPage} />
                            {/* <PrivateRoute path="/loan/:feature" component={LoanPage} /> */}
                            <PrivateRoute exact path="/loan" component={LoanPage} />
                            <PrivateRoute exact path="/user" component={UserPage} />
                            <PrivateRoute exact path="/change" component={ChangePage} />

                            <Route path="*" component={ErrorPage} />
                        </Switch>
                    </Layout>
                </Router>
                <GlobalStyle />
            </IntlProvider>
        </Provider>
    );
}

export default App;
