import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";

import { useIntl } from "react-intl";

import logo from "../../images/ostosraha-logo.svg";

import { NavigationContainer } from "../NavigationContainer";
import { LinkContainer } from "../LinkContainer";
import { breakpoints, colors } from "../../constants/styles";

import { AutenticationButton, AutenticationText, AutenticationImage } from "../AutenticationButton";
import { logout } from "../../actions/user";
import { Icon } from "../Icon";

import { messages } from "./messages";

const HeaderContainer = styled.header`
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 64px;
    box-shadow: 0 3px 4px 0 #cceff5;
    background-color: rgba(255, 255, 255, 0.85);
    width: 100%;
    margin: 24px 0 16px 0;
    @media ${breakpoints.mobile} {
        margin: 0px;
        height: 56px;
        position: fixed;
        background-color: #e8f3e5;
    }
`;

const Logo = styled.div`
    display: flex;
    margin-top: 8px;
    margin-right: 16px;
    padding-right: 16px;
    width: 140px;
    @media ${breakpoints.mobile} {
        margin-top: 0px;
        padding: 9px 0px 24px 16px;
    }
    img {
        width: 139px;
        height: 35px;
        margin-bottom: 16px;
        @media ${breakpoints.mobile} {
            width: 95px;
            margin: 0;
        }
        @media ${breakpoints.tablet} {
            margin-bottom: 0;
        }
    }
`;

const LinksContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media ${breakpoints.mobile} {
        display: none;
    }
    @media ${breakpoints.tablet} {
        display: none;
    }
`;

const ReturnLinkContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 6px;
    @media ${breakpoints.mobile} {
        padding-right: 20px;
    }
`;

const UnloggedReturnLink = styled.a`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #196da8;
    @media ${breakpoints.mobile} {
        display: none;
    }
`;

const TabletNavigation = styled.div`
    display: none;
    @media ${breakpoints.tablet} {
        display: flex;
    }
    @media ${breakpoints.mobile} {
        display: none;
    }
    justify-content: space-between;
    width: 90%;
`;

const AutenticationParagraph = styled.div`
    margin-right: 22px;
`;

const AutenticationFix = styled.div`
    @media ${breakpoints.tablet} {
        margin-top: -14px;
    }
    @media ${breakpoints.mobile} {
        margin-bottom: 7px;
    }
`;

const Navigation = ({ navigationItems }) => {
    return (
        <>
            {navigationItems.map((item, index) => {
                return (
                    <LinkContainer key={index} to={item.linkTo}>
                        <span>{item.navigationText}</span>
                    </LinkContainer>
                );
            })}
        </>
    );
};

export const Header = () => {
    const dispatch = useDispatch();
    const hasToken = useSelector((state) => {
        return state.user.token;
    });
    const isLogoutPage = useRouteMatch("/logout");
    const isNoLoanPage = useRouteMatch("/no-loan");
    const history = useHistory();

    const { formatMessage: f } = useIntl();

    const navigationItems = [
        {
            linkTo: "/front",
            navigationText: f(messages.home)
        },
        {
            linkTo: "/loan",
            navigationText: f(messages.loan)
        },
        {
            linkTo: "/change",
            navigationText: f(messages.change)
        },
        {
            linkTo: "/user",
            navigationText: f(messages.user)
        },
        {
            linkTo: "/contact",
            navigationText: f(messages.contact)
        }
    ];

    return (
        <HeaderContainer>
            <NavigationContainer>
                <a href="/front">
                    <Logo>
                        <img src={logo} alt="" />
                    </Logo>
                </a>
                {hasToken && (
                    <>
                        <LinksContainer>
                            <Navigation navigationItems={navigationItems} />
                        </LinksContainer>

                        <AutenticationButton
                            mobilePadding={true}
                            onClick={() => {
                                dispatch(logout(history));
                            }}>
                            <AutenticationImage>
                                <Icon
                                    icon={["fa", "sign-out-alt"]}
                                    size="1x"
                                    color={colors.green}
                                />
                            </AutenticationImage>
                            <AutenticationText>{f(messages.logout)}</AutenticationText>
                        </AutenticationButton>
                    </>
                )}
                {!hasToken && (
                    <>
                        <ReturnLinkContainer>
                            <AutenticationParagraph>
                                <UnloggedReturnLink href={"https://svea.ostosraha.fi"}>
                                    {f(messages.ostosraha)}
                                </UnloggedReturnLink>
                            </AutenticationParagraph>
                            {isLogoutPage && (
                                <AutenticationFix>
                                    <AutenticationButton
                                        onClick={() => {
                                            history.push("/");
                                        }}>
                                        <Icon
                                            icon={["fa", "sign-in-alt"]}
                                            size="1x"
                                            color={colors.green}
                                        />
                                        &nbsp;
                                        <AutenticationText>
                                            {f(messages.loginAgain)}
                                        </AutenticationText>
                                    </AutenticationButton>
                                </AutenticationFix>
                            )}

                            {isNoLoanPage && (
                                <AutenticationFix>
                                    <AutenticationButton
                                        onClick={() => {
                                            dispatch(logout(history));
                                        }}>
                                        {f(messages.logout)}&nbsp;
                                        <Icon
                                            icon={["fa", "sign-out-alt"]}
                                            size="1x"
                                            color={colors.green}
                                        />
                                    </AutenticationButton>
                                </AutenticationFix>
                            )}
                        </ReturnLinkContainer>
                    </>
                )}
            </NavigationContainer>
            {hasToken && (
                <TabletNavigation>
                    <Navigation navigationItems={navigationItems} />
                </TabletNavigation>
            )}
        </HeaderContainer>
    );
};
