import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";

import { LoanInfo } from "../../../components/LoanInfo/LoanInfo";
import { Currency } from "../../../components/Currency";
import { Grid2 } from "../../../components/Grid";
import { SmallText } from "../../../components/Fonts/SmallText";

import {
    LoanModalContainer,
    UserInfo,
    UserInfoContainerOne,
    UserInfoContainerTwo,
    TitleValueContainer
} from "./styled";

import { messages } from "./LoanSummary.messages";

export const LoanSummary = () => {
    const { customerBasicInfo, activeAccountInfo } = useSelector((state) => {
        return {
            customerBasicInfo: state.user.customer.customerBasicInfo,
            activeAccountInfo: state.accounts.activeAccountInfo
        };
    });

    return (
        <LoanModalContainer>
            <LoanInfo />
            <UserInfo>
                <Grid2>
                    <UserInfoContainerOne>
                        <TitleValueContainer>
                            <SmallText bold>
                                <FormattedMessage {...messages.name} />
                            </SmallText>
                            <SmallText>
                                {customerBasicInfo.givenName} {customerBasicInfo.surName}
                            </SmallText>
                        </TitleValueContainer>
                        <TitleValueContainer>
                            <SmallText bold>
                                <FormattedMessage {...messages.accountnumber} />
                            </SmallText>
                            <SmallText>{activeAccountInfo.accountNumber}</SmallText>
                        </TitleValueContainer>
                        <TitleValueContainer>
                            <SmallText bold>
                                <FormattedMessage {...messages.bankaccount} />
                            </SmallText>
                            <SmallText>
                                {activeAccountInfo.disbursementAccount.externalAccountNumber}
                            </SmallText>
                        </TitleValueContainer>
                        <TitleValueContainer>
                            <SmallText bold>
                                <FormattedMessage {...messages.personalnumber} />
                            </SmallText>
                            <SmallText>{customerBasicInfo.ssn}</SmallText>
                        </TitleValueContainer>
                    </UserInfoContainerOne>
                </Grid2>
                <Grid2>
                    <UserInfoContainerTwo>
                        <TitleValueContainer>
                            <SmallText bold>
                                <FormattedMessage {...messages.createddate} />
                            </SmallText>
                            <SmallText>
                                {format(parseISO(activeAccountInfo.createDate), "dd.MM.yyyy")}
                            </SmallText>
                        </TitleValueContainer>
                        <TitleValueContainer>
                            <SmallText bold>
                                <FormattedMessage {...messages.appliedamount} />
                            </SmallText>
                            <SmallText>
                                <Currency>{activeAccountInfo.appliedAmount}</Currency>
                            </SmallText>
                        </TitleValueContainer>
                        <TitleValueContainer>
                            <SmallText bold>
                                <FormattedMessage {...messages.remainingtenor} />
                            </SmallText>
                            <SmallText>{activeAccountInfo.remainingTenor}</SmallText>
                        </TitleValueContainer>
                        <TitleValueContainer>
                            <SmallText bold>
                                <FormattedMessage {...messages.monthlyfee} />
                            </SmallText>
                            <SmallText>
                                <Currency>{activeAccountInfo.monthlyCost}</Currency>
                            </SmallText>
                        </TitleValueContainer>
                    </UserInfoContainerTwo>
                </Grid2>
            </UserInfo>
        </LoanModalContainer>
    );
};
