import { takeEvery, put, call, select } from "redux-saga/effects";
import {
    fetchAccountsSuccess,
    fetchAccountDocumentIDsTrigger,
    setSelectedAccount,
    setSelectedAccountInfo,
} from "../actions/account";
import { fetchAccount } from "../util/api";

import { USER_FETCH_CUSTOMER_SUCCESS } from "../constants/user";
import { FETCH_ACCOUNT_TRIGGER } from "../constants/account";
import { selectToken } from "../selectors/token";
import { push } from "connected-react-router";
import { updateInitialized } from "../actions/user";

export function* handleUpdatedAccount() {
    const token = yield select(selectToken);

    const accountId = yield select((state) => state.accounts.activeAccount.id);

    const accounts = yield call(fetchAccount, {
        token,
        accountId,
    });

    yield put(fetchAccountsSuccess(accounts));
}

export function* handleFetchAccount(action) {
    const token = yield select(selectToken);

    const { accountList } = action.payload;

    if (accountList && accountList.length > 0) {
        const accountInfo = accountList[0];
        yield put(setSelectedAccount(accountInfo));
    } else {
        return yield put(push("/error"));
    }

    const accountId = yield select((state) => state.accounts.activeAccount.id);
    const accountNumber = yield select((state) => state.accounts.activeAccount.accountNumber);

    console.log("got account id", accountId);

    if (!accountId) {
        return yield put(push("/error"));
    }

    const accounts = yield call(fetchAccount, {
        token,
        accountId,
    });

    if (accounts.state === "PRE_SETTLED" || accounts.state === "WRITTEN_OFF") {
        return (window.location.href = "/no-loan");
    }

    yield put(fetchAccountsSuccess(accounts));
    yield put(fetchAccountDocumentIDsTrigger(token, accountNumber));
    yield put(setSelectedAccountInfo(accounts));
    yield put(updateInitialized(true));
}

export function* watchUpdatedAccount() {
    yield takeEvery(FETCH_ACCOUNT_TRIGGER, handleUpdatedAccount);
}

export function* watchFetchAccount() {
    yield takeEvery(USER_FETCH_CUSTOMER_SUCCESS, handleFetchAccount);
}

export const fetchUserAccount = [watchUpdatedAccount, watchFetchAccount];
