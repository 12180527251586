import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { fetchDocument } from "../../../util/api";

import { ItemList } from "../../../components/ItemList";

import { StyledParagraph } from "./styled";
import { messages } from "./LoanContracts.messages";
import { ButtonLink } from "../../../components/LinkContainer";

export const LoanContracts = ({ data }) => {
    const { formatMessage: f } = useIntl();

    const documentItems = [
        <ButtonLink
            onClick={() =>
                fetchDocument({
                    token: data.token,
                    documentId: data.documents.agreement.documentId
                })
            }>
            {f(messages.listitem1)}
        </ButtonLink>,
        <ButtonLink
            onClick={() =>
                fetchDocument({
                    token: data.token,
                    documentId: data.documents.terms.documentId
                })
            }>
            {f(messages.listitem2)}
        </ButtonLink>,
        <ButtonLink
            onClick={() =>
                fetchDocument({
                    token: data.token,
                    documentId: data.documents.consumerCreditInformation.documentId
                })
            }>
            {f(messages.listitem3)}
        </ButtonLink>
    ];
    return (
        <>
            <StyledParagraph>
                <FormattedMessage {...messages.ingress} />
            </StyledParagraph>
            <ItemList items={documentItems} />
        </>
    );
};
