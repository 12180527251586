import React from "react";
import { useIntl } from "react-intl";
import { format, parseISO } from "date-fns";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { LoanCalculator } from "./LoanCalculator";
import { BoxContent, BoxTitle, BoxAmount } from "../BoxContent";
import { LoanCalculatorInformation } from "./LoanCalculatorInformation";
import { DEFAULT_FORMAT_DATE } from "../../constants/dateTime";
import { Currency } from "../Currency";

import { messages } from "./messages";

const LoanInfoCalculatorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
`;

export const LoanCalculatorDateContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
`;

export const LoanCalculatorDate = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin: 0 0 32px 0;
`;

export const LoanCalculatorDateDescription = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0 16px 0;
    width: 100%;
`;

export const TextInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 320px;
    text-align: center;
    margin: 24px 0 0 0;
    height: 100%;
`;

export const TextInfoParagraph = styled.p`
    margin: 8px 0;
    padding: 0;
`;

export function LoanInfo() {
    const now = format(new Date(), "yyyy-MM-dd'T'HH:mmxxx");
    const formattedNow = format(parseISO(now), DEFAULT_FORMAT_DATE);
    const { activeAccountInfo } = useSelector((state) => {
        return {
            activeAccountInfo: state.accounts.activeAccountInfo
        };
    });

    const { formatMessage: f } = useIntl();

    const { appliedAmount, remainingPrincipal } = activeAccountInfo;

    let paidAmount = parseFloat(appliedAmount) - parseFloat(remainingPrincipal);
    let percentage = Math.round((paidAmount / appliedAmount) * 100);

    if (percentage > 100) {
        percentage = 100;
    }

    if (paidAmount > appliedAmount) {
        paidAmount = appliedAmount;
    }

    return (
        <>
            <BoxContent center>
                <BoxTitle>{`${f(messages.title)} ${formattedNow}`}</BoxTitle>
                <BoxAmount>
                    <Currency>{remainingPrincipal}</Currency>
                </BoxAmount>
            </BoxContent>
            <LoanCalculatorDateDescription>{f(messages.description)}</LoanCalculatorDateDescription>

            <LoanInfoCalculatorContainer>
                <Container>
                    <LoanCalculator percentage={percentage} />
                    <LoanCalculatorInformation
                        paidTotal={`${paidAmount}`}
                        loanAmount={`${activeAccountInfo.appliedAmount}`}
                    />
                </Container>
                <TextInfoContainer>{f(messages.text, messages.text.values)}</TextInfoContainer>
            </LoanInfoCalculatorContainer>
        </>
    );
}
