import { createSelector } from "reselect";

const getPaymentPlans = (state) =>
    state.debtStatus.status.paymentPlans ? state.debtStatus.status.paymentPlans : [];
const getStatements = (state) =>
    state.debtStatus.status.statements ? state.debtStatus.status.statements : [];
const getEntities = (state) =>
    state.debtStatus.status.entities ? state.debtStatus.status.entities : [];
const getFirstStatement = (state) =>
    state.debtStatus.status.firstStatement ? state.debtStatus.status.firstStatement : {};
const getLatestStatement = (state) =>
    state.debtStatus.status.latestStatement ? state.debtStatus.status.latestStatement : {};

export const selectPaymentPlans = () =>
    createSelector([getPaymentPlans], (paymentPlans) => paymentPlans);

export const selectStatements = () => createSelector([getStatements], (statements) => statements);

export const selectEntities = () => createSelector([getEntities], (entities) => entities);

export const selectFirstStatement = () => createSelector([getFirstStatement], (item) => item);

export const selectLatestStatement = () => createSelector([getLatestStatement], (item) => item);
