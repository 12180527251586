import {
    FETCH_PAYMENT_PLANS_AND_STATEMENTS_TRIGGER,
    FETCH_PAYMENT_PLANS_AND_STATEMENTS_SUCCESS
} from "../constants/debtStatus";

export function fetchDebtStatusTrigger() {
    return {
        type: FETCH_PAYMENT_PLANS_AND_STATEMENTS_TRIGGER
    };
}

export function fetchDebtStatusSuccess(payload) {
    return {
        type: FETCH_PAYMENT_PLANS_AND_STATEMENTS_SUCCESS,
        payload
    };
}
