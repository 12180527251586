/* DEPRECATED */
/* DEPRECATED */ import { FETCH_PAYMENT_PLANS_SUCCESS } from "../constants/paymentPlans";
/* DEPRECATED */
/* DEPRECATED */ export const initialState = {
    /* DEPRECATED */ paymentPlans: []
    /* DEPRECATED */
};
/* DEPRECATED */
/* DEPRECATED */ export function paymentPlansReducer(state = initialState, action) {
    /* DEPRECATED */ switch (action.type) {
        /* DEPRECATED */ case FETCH_PAYMENT_PLANS_SUCCESS: {
            /* DEPRECATED */ console.log("got payment plans to update", action);
            /* DEPRECATED */ return { ...state, paymentPlans: action.payload };
            /* DEPRECATED */
        }
        /* DEPRECATED */ default:
            /* DEPRECATED */ return state;
        /* DEPRECATED */
    }
    /* DEPRECATED */
}
