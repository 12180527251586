import namor from "namor";
import { format, addDays, addMonths } from "date-fns";
import { DEFAULT_FORMAT_DATE } from "../constants/dateTime";

export async function httpFaker(url, method, data, response, timeout) {
    return new Promise((resolve) => {
        setTimeout(
            () => {
                console.log("MOCK: " + url, method, data, response);
                resolve(response);
            },
            timeout ? timeout : 600
        );
    });
}

export function getCustomerInfoMock(url, method, data) {
    return httpFaker(url, method, data, {
        customerBasicInfo: {
            customerId: "50701",
            century: "",
            ssn: "070770-905D",
            givenName: "Givenname",
            surName: "Surname",
            email: "jason.wang@leandev.se",
            phoneCode: null,
            phoneNumber: null,
            mobilePhoneCode: "+358",
            mobilePhoneNumber: "43213213",
            preferredContactChannel: null,
            selfAssessedPep: "NULL",
            selfAssessedPepDescription: null,
            nationality: "FI",
            hasGuardian: false
        },
        address: {
            streetAddress: "Katuosoite 11",
            zipCode: "00430",
            city: "Helsinki",
            country: "FI",
            coAddress: null,
            manuallyMaintained: false
        },
        accountList: [
            {
                id: "15350",
                accountNumber: "7103195",
                nickName: null,
                type: "REGULAR_LOAN",
                extraInfos: {
                    remainingPrincipal: "0.00"
                }
            }
        ]
    });
}

export function getAccessTokenMock(url, method, data) {
    return httpFaker(url, method, data, {
        accessToken:
            "SzgW6H1USnp3NGX+XUzcm/32td/EfoUNGv1+qFA9Z8u3fuHt78KHU4+snek8PmtR8bv3z5WR93BDHzVIiyVjAutQwcilXtxJZBHQT1WKFidr0Ku8lvLDZ1EqvnmpQfVLFIbECfRob2mXgraGYMpck7ZOaRzCpLGyl+yad72iC9a2zbQSJbxPOD4dlBzHcIcZOaU0BAsNW+zjiU/47b4t1CifKWhie3rQzx8eU+oOmylwKonZu8HG91vfqJZS0ArYGT/oYTSO6LYV8c+p2AsBnPw23P3bfSukqlxcxUaE9HN75tq4qX/p7ElGWvSkpaNvYZ1ExNwujvpn6nnY9Esa2kofMiS8vt5RMu2p/a2wHYCbY1yToVvPSzEQ5xe/6bIJ"
    });
}

export function getAccountMock(url, method, data) {
    return httpFaker(url, method, data, {
        accountNumber: "7103195",
        appliedAmount: 1000,
        createDate: "2019-11-18 14:26:12",
        disbursementAccount: {
            disbursementType: "IBAN",
            externalAccountNumber: "FI4114421947279566",
            id: "6651"
        },
        id: "15350",
        interestRate: 0.199,
        mainHolderId: "50701",
        mandateList: [],
        monthlyCost: 35.74,
        product: {
            amountToUseInCalculationServiceFee: "ORIGINAL_AMOUNT",
            baseInterestRate: 0.199,
            collectionFeeAccount: 0,
            collectionFeeStatement: 0,
            createDate: "2019-11-04 14:05:05",
            description: "Ostosraha 2",
            directDebitFee: 0,
            dueDay: 28,
            id: "1",
            lateInterestRate: 0.199,
            latePaymentFee: 0,
            name: "Ostosraha 2",
            ocrFee: 0,
            reminderFee: 5,
            serviceFeeRate: 1.0e-4,
            startupFee: 0
        },
        promissoryNoteDocumentId: "f36f5f26b1b9459187c92c8b943607c1",
        remainingPrincipal: 820.0,
        remainingTenor: 12,
        state: "OPEN",
        statementChannel: "PAPER"
    });
}

export function getDocumentIdsMock(url, method, data) {
    return httpFaker(url, method, data, [
        {
            documentId: "07d5eeeaf1bc44e182d9a8e30cb10b10",
            filename: "Lainaehdot_2015-06-01.pdf",
            uploadDate: 1591024944306,
            length: 47737,
            metadata: {
                documentId: "07d5eeeaf1bc44e182d9a8e30cb10b10",
                version: 0,
                creator: "ronlin",
                ssn: null,
                customerId: "50228",
                applicationId: null,
                applicantId: null,
                accountNumber: "7139561",
                purchaseId: null,
                documentType: "PROMISSORY_NOTE",
                contentType: "application/pdf",
                signStatus: "SIGNED",
                signType: "MANUAL",
                note: null,
                autoCleanDays: null,
                serviceName: null,
                encoding: null,
                customerType: null,
                tags: {},
                lastUpdateDate: null,
                smeId: null,
                uploadChannel: null
            }
        },
        {
            documentId: "68077e99baf8451aa045711a1c609e34",
            filename: "kuluttajaluottotiedot_2372871e96480f56620_6_4000_960.pdf",
            uploadDate: 1590471813695,
            length: 5869,
            metadata: {
                documentId: "68077e99baf8451aa045711a1c609e34",
                version: 0,
                creator: "ronlin",
                ssn: null,
                customerId: "50228",
                applicationId: null,
                applicantId: null,
                accountNumber: "7139561",
                purchaseId: null,
                documentType: "PROMISSORY_NOTE",
                contentType: "application/pdf",
                signStatus: "SIGNED",
                signType: "MANUAL",
                note: null,
                autoCleanDays: null,
                serviceName: null,
                encoding: null,
                customerType: null,
                tags: {},
                lastUpdateDate: null,
                smeId: null,
                uploadChannel: null
            }
        },
        {
            documentId: "49a4a387b5d2448a8274453d47a515c3",
            filename: "sopimus_200002372871_e96480f566.pdf",
            uploadDate: 1590471813654,
            length: 4854,
            metadata: {
                documentId: "49a4a387b5d2448a8274453d47a515c3",
                version: 0,
                creator: "ronlin",
                ssn: null,
                customerId: "50228",
                applicationId: null,
                applicantId: null,
                accountNumber: "7139561",
                purchaseId: null,
                documentType: "PROMISSORY_NOTE",
                contentType: "application/pdf",
                signStatus: "SIGNED",
                signType: "MANUAL",
                note: null,
                autoCleanDays: null,
                serviceName: null,
                encoding: null,
                customerType: null,
                tags: {},
                lastUpdateDate: null,
                smeId: null,
                uploadChannel: null
            }
        }
    ]);
}

export function getAccountStatementsMock(url, method, data) {
    return httpFaker(url, method, data, [
        {
            seriesNum: null,
            dueDate: null,
            paidInFullDate: null,
            overdueDays: null,
            statementAmount: 1.5166244166666667,
            paidAmount: 0,
            unpaidAmount: 1.5166244166666667,
            statementType: "PENDING",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PENDING",
            createdDate: "2022-02-07 04:00:10",
            documentId: null
        },
        {
            seriesNum: "1048",
            dueDate: "2022-02-28 00:00:00",
            paidInFullDate: null,
            overdueDays: 0.0,
            statementAmount: 50.07,
            paidAmount: 0,
            unpaidAmount: 50.07,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "UNPDAID",
            createdDate: "2022-02-07 04:00:10",
            documentId: "62007e09fa4e311a88c74be9"
        },
        {
            seriesNum: "1047",
            dueDate: "2022-01-29 00:00:00",
            paidInFullDate: null,
            overdueDays: 17.0,
            statementAmount: 159.19,
            paidAmount: 0,
            unpaidAmount: 159.19,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "OVERDUE",
            createdDate: "2022-01-10 04:05:20",
            documentId: "61db99421651ed2062850433"
        },
        {
            seriesNum: "1046",
            dueDate: "2021-12-29 00:00:00",
            paidInFullDate: null,
            overdueDays: 48.0,
            statementAmount: 157.33,
            paidAmount: 83.33,
            unpaidAmount: 74,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "OVERDUE",
            createdDate: "2021-12-08 04:01:17",
            documentId: "61b013e61651ed206284d18b"
        },
        {
            seriesNum: "1045",
            dueDate: "2021-11-29 00:00:00",
            paidInFullDate: "2022-01-16 00:00:00",
            overdueDays: 48.0,
            statementAmount: 149.5,
            paidAmount: 149.5,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2021-11-08 04:12:56",
            documentId: "61889161e03a5b64b3ac21b0"
        },
        {
            seriesNum: "1044",
            dueDate: "2021-10-29 00:00:00",
            paidInFullDate: "2021-12-21 00:00:00",
            overdueDays: 53.0,
            statementAmount: 148.57,
            paidAmount: 148.57,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2021-10-08 04:01:14",
            documentId: "615f9ab600302101c23fc878"
        },
        {
            seriesNum: "1043",
            dueDate: "2021-09-29 00:00:00",
            paidInFullDate: "2021-09-22 00:00:00",
            overdueDays: 0.0,
            statementAmount: 57.62,
            paidAmount: 57.62,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2021-09-08 04:01:28",
            documentId: "61380df118c152707fd4ed1a"
        },
        {
            seriesNum: "1042",
            dueDate: "2021-08-29 00:00:00",
            paidInFullDate: "2021-08-26 00:00:00",
            overdueDays: 0.0,
            statementAmount: 62.18,
            paidAmount: 62.18,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2021-08-09 04:08:36",
            documentId: "61108b0918c152707fd4ad8a"
        },
        {
            seriesNum: "1041",
            dueDate: "2021-07-29 00:00:00",
            paidInFullDate: "2021-08-26 00:00:00",
            overdueDays: 28.0,
            statementAmount: 155.73,
            paidAmount: 155.73,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2021-07-08 04:03:42",
            documentId: "60e6533d18c152707fd44dd6"
        },
        {
            seriesNum: "1040",
            dueDate: "2021-06-29 00:00:00",
            paidInFullDate: "2021-08-26 00:00:00",
            overdueDays: 58.0,
            statementAmount: 156.59,
            paidAmount: 156.59,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2021-06-08 04:09:45",
            documentId: "60bec90c7d81657d3e61e2c8"
        },
        {
            seriesNum: "1039",
            dueDate: "2021-05-29 00:00:00",
            paidInFullDate: "2021-07-06 00:00:00",
            overdueDays: 38.0,
            statementAmount: 157.12,
            paidAmount: 157.12,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "CURING",
            createdDate: "2021-05-10 04:00:45",
            documentId: "6098943c6b1e4158023df5ea"
        },
        {
            seriesNum: "1038",
            dueDate: "2021-04-29 00:00:00",
            paidInFullDate: "2021-06-08 00:00:00",
            overdueDays: 40.0,
            statementAmount: 155.41,
            paidAmount: 155.41,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "CURING",
            createdDate: "2021-04-08 04:00:15",
            documentId: "606e5d6eb1ad373bb5b9b2e9"
        },
        {
            seriesNum: "1037",
            dueDate: "2021-03-29 00:00:00",
            paidInFullDate: "2021-05-20 00:00:00",
            overdueDays: 52.0,
            statementAmount: 156.31,
            paidAmount: 156.31,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "CURING",
            createdDate: "2021-03-08 04:06:54",
            documentId: "60465beec39f137b029ca0f5"
        },
        {
            seriesNum: "1036",
            dueDate: "2021-02-28 00:00:00",
            paidInFullDate: "2021-03-16 00:00:00",
            overdueDays: 16.0,
            statementAmount: 64.91,
            paidAmount: 64.91,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2021-02-08 04:01:31",
            documentId: "6020a44c2c947b1c337fe3b0"
        },
        {
            seriesNum: "1035",
            dueDate: "2021-01-29 00:00:00",
            paidInFullDate: "2021-03-16 00:00:00",
            overdueDays: 46.0,
            statementAmount: 156.41,
            paidAmount: 156.41,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "CURING",
            createdDate: "2021-01-08 04:03:52",
            documentId: "458bf106ffdb4ed882bcdd445a45016b"
        },
        {
            seriesNum: "1034",
            dueDate: "2020-12-29 00:00:00",
            paidInFullDate: "2021-02-22 00:00:00",
            overdueDays: 55.0,
            statementAmount: 155.23,
            paidAmount: 155.23,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-12-08 04:02:54",
            documentId: "7552e0b806af4acfa11019fcc9e969dc"
        },
        {
            seriesNum: "1033",
            dueDate: "2020-11-29 00:00:00",
            paidInFullDate: "2021-01-14 00:00:00",
            overdueDays: 46.0,
            statementAmount: 149.53,
            paidAmount: 149.53,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-11-09 04:10:39",
            documentId: "f224e9eb849e43d88b73e513e359e6ac"
        },
        {
            seriesNum: "1032",
            dueDate: "2020-10-29 00:00:00",
            paidInFullDate: "2020-11-20 00:00:00",
            overdueDays: 22.0,
            statementAmount: 148.57,
            paidAmount: 148.57,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-10-08 04:03:33",
            documentId: "8ac0bd3f7d6f459a9d070b5935aa01ab"
        },
        {
            seriesNum: "1031",
            dueDate: "2020-09-29 00:00:00",
            paidInFullDate: "2020-09-25 00:00:00",
            overdueDays: 0.0,
            statementAmount: 144.96,
            paidAmount: 144.96,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-09-09 22:08:59",
            documentId: "326b838ced354e0494e550954011257d"
        },
        {
            seriesNum: "1030",
            dueDate: "2020-08-29 00:00:00",
            paidInFullDate: "2020-08-06 00:00:00",
            overdueDays: 0.0,
            statementAmount: 157.12,
            paidAmount: 157.12,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-08-08 22:04:16",
            documentId: "b1e48ca4bd8444be92299f97f4f9e935"
        },
        {
            seriesNum: "1029",
            dueDate: "2020-07-29 00:00:00",
            paidInFullDate: "2020-07-30 00:00:00",
            overdueDays: 1.0,
            statementAmount: 153.2,
            paidAmount: 153.2,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-07-13 22:17:58",
            documentId: "6608704ff1e0485eb50f36724f0b1a70"
        },
        {
            seriesNum: "1028",
            dueDate: "2020-06-29 00:00:00",
            paidInFullDate: "2020-07-30 00:00:00",
            overdueDays: 31.0,
            statementAmount: 239.57,
            paidAmount: 239.57,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-06-08 22:48:17",
            documentId: "4ed71cc849b949e8b4e4b6bd9b366192"
        },
        {
            seriesNum: "1027",
            dueDate: "2020-05-29 00:00:00",
            paidInFullDate: "2020-05-29 00:00:00",
            overdueDays: 0.0,
            statementAmount: 0,
            paidAmount: 0,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-06-03 22:11:31",
            documentId: null
        },
        {
            seriesNum: "1026",
            dueDate: "2020-04-29 00:00:00",
            paidInFullDate: "2020-07-03 00:00:00",
            overdueDays: 65.0,
            statementAmount: 158.58,
            paidAmount: 158.58,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-04-08 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1025",
            dueDate: "2020-03-29 00:00:00",
            paidInFullDate: "2020-05-05 00:00:00",
            overdueDays: 37.0,
            statementAmount: 241.69,
            paidAmount: 241.69,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-03-08 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1024",
            dueDate: "2020-01-31 00:00:00",
            paidInFullDate: "2020-03-26 00:00:00",
            overdueDays: 55.0,
            statementAmount: 158.58,
            paidAmount: 158.58,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-01-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1023",
            dueDate: "2019-12-31 00:00:00",
            paidInFullDate: "2020-01-30 00:00:00",
            overdueDays: 30.0,
            statementAmount: 154.42,
            paidAmount: 154.42,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-12-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1022",
            dueDate: "2019-11-30 00:00:00",
            paidInFullDate: "2019-12-23 00:00:00",
            overdueDays: 23.0,
            statementAmount: 155.09,
            paidAmount: 155.09,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-11-09 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1021",
            dueDate: "2019-10-31 00:00:00",
            paidInFullDate: "2019-11-14 00:00:00",
            overdueDays: 14.0,
            statementAmount: 149.38,
            paidAmount: 149.38,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-10-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1020",
            dueDate: "2019-09-30 00:00:00",
            paidInFullDate: "2019-10-22 00:00:00",
            overdueDays: 22.0,
            statementAmount: 155.11,
            paidAmount: 155.11,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-09-09 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1019",
            dueDate: "2019-08-31 00:00:00",
            paidInFullDate: "2019-09-13 00:00:00",
            overdueDays: 13.0,
            statementAmount: 151.03,
            paidAmount: 151.03,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-08-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1018",
            dueDate: "2019-07-31 00:00:00",
            paidInFullDate: "2019-08-16 00:00:00",
            overdueDays: 16.0,
            statementAmount: 153.57,
            paidAmount: 153.57,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-07-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1017",
            dueDate: "2019-06-30 00:00:00",
            paidInFullDate: "2019-07-22 00:00:00",
            overdueDays: 22.0,
            statementAmount: 259.31,
            paidAmount: 259.31,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-06-09 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1016",
            dueDate: "2019-04-30 00:00:00",
            paidInFullDate: "2019-04-27 00:00:00",
            overdueDays: 0.0,
            statementAmount: 148.82,
            paidAmount: 148.82,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-04-09 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1015",
            dueDate: "2019-03-31 00:00:00",
            paidInFullDate: "2019-04-15 00:00:00",
            overdueDays: 15.0,
            statementAmount: 459.97,
            paidAmount: 459.97,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-03-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1014",
            dueDate: "2018-11-30 00:00:00",
            paidInFullDate: "2018-12-03 00:00:00",
            overdueDays: 3.0,
            statementAmount: 148.93,
            paidAmount: 148.93,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2018-11-09 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1013",
            dueDate: "2018-10-31 00:00:00",
            paidInFullDate: "2018-11-12 00:00:00",
            overdueDays: 12.0,
            statementAmount: 366.68,
            paidAmount: 366.68,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2018-10-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1012",
            dueDate: "2018-07-31 00:00:00",
            paidInFullDate: "2018-08-20 00:00:00",
            overdueDays: 20.0,
            statementAmount: 154.33,
            paidAmount: 154.33,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2018-07-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1011",
            dueDate: "2018-06-30 00:00:00",
            paidInFullDate: "2018-08-07 00:00:00",
            overdueDays: 38.0,
            statementAmount: 365.68,
            paidAmount: 365.68,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2018-06-09 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1010",
            dueDate: "2018-03-31 00:00:00",
            paidInFullDate: "2018-05-25 00:00:00",
            overdueDays: 55.0,
            statementAmount: 164.37,
            paidAmount: 164.37,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2018-03-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1009",
            dueDate: "2018-02-28 00:00:00",
            paidInFullDate: "2018-04-09 00:00:00",
            overdueDays: 40.0,
            statementAmount: 164.42,
            paidAmount: 164.42,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2018-02-07 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1008",
            dueDate: "2018-01-31 00:00:00",
            paidInFullDate: "2018-03-20 00:00:00",
            overdueDays: 48.0,
            statementAmount: 279.36,
            paidAmount: 279.36,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2018-01-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1007",
            dueDate: "2017-11-30 00:00:00",
            paidInFullDate: "2018-01-09 00:00:00",
            overdueDays: 40.0,
            statementAmount: 154.42,
            paidAmount: 154.42,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2017-11-09 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1006",
            dueDate: "2017-10-31 00:00:00",
            paidInFullDate: "2017-11-14 00:00:00",
            overdueDays: 14.0,
            statementAmount: 149.58,
            paidAmount: 149.58,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2017-10-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1005",
            dueDate: "2017-09-30 00:00:00",
            paidInFullDate: "2017-10-04 00:00:00",
            overdueDays: 4.0,
            statementAmount: 149.69,
            paidAmount: 149.69,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2017-09-09 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1004",
            dueDate: "2017-08-31 00:00:00",
            paidInFullDate: "2017-08-17 00:00:00",
            overdueDays: 0.0,
            statementAmount: 149.69,
            paidAmount: 149.69,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2017-08-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1003",
            dueDate: "2017-07-31 00:00:00",
            paidInFullDate: "2017-07-13 00:00:00",
            overdueDays: 0.0,
            statementAmount: 150.2,
            paidAmount: 150.2,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2017-07-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1002",
            dueDate: "2017-06-30 00:00:00",
            paidInFullDate: "2017-06-10 00:00:00",
            overdueDays: 0.0,
            statementAmount: 150.2,
            paidAmount: 150.2,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2017-06-09 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1001",
            dueDate: "2017-05-31 00:00:00",
            paidInFullDate: "2017-05-10 00:00:00",
            overdueDays: 0.0,
            statementAmount: 150.2,
            paidAmount: 150.2,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2017-05-10 00:00:00",
            documentId: null
        },
        {
            seriesNum: "1000",
            dueDate: "2017-04-30 00:00:00",
            paidInFullDate: "2017-04-13 00:00:00",
            overdueDays: 0.0,
            statementAmount: 150.2,
            paidAmount: 150.2,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2017-04-09 00:00:00",
            documentId: null
        }
    ]);
}
//* Get Payment free month
export function getSpecialPaymentAgreementsMock(url, method, data) {
    return httpFaker(url, method, data, [
        {
            id: 0,
            state: "FINISHED",
            requestMonth: "2020-03",
            accountNumber: "7103195",
            paymentAgreementType: "PAYMENT_FREE_MONTH",
            administrativeFee: 0
        },
        {
            id: 1,
            state: "SCHEDULED",
            requestMonth: "2020-05",
            accountNumber: "7103195",
            paymentAgreementType: "PAYMENT_FREE_MONTH",
            administrativeFee: 0
        },
        {
            id: 2,
            state: "SCHEDULED",
            requestMonth: "2020-07",
            accountNumber: "7103195",
            paymentAgreementType: "PAYMENT_FREE_MONTH",
            administrativeFee: 0
        }
    ]);
}

//* Set payment free month
export function setSpecialPaymentAgreementsMock(url, method, data) {
    const randomResponse = Math.random() >= 0.5;
    console.log("Mock random response", randomResponse);
    return httpFaker(url, method, data, randomResponse);
}

export function getPaymentPlansMock(url, method, data) {
    return httpFaker(url, method, data, [
        {
            seriesNum: 1071,
            dueDate: "2023-12-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 271.55,
            interest: 5.63,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.18
        },
        {
            seriesNum: 1070,
            dueDate: "2023-11-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 266.45,
            interest: 11.16,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1069,
            dueDate: "2023-10-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 261.03,
            interest: 16.58,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1068,
            dueDate: "2023-09-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 255.72,
            interest: 21.89,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1067,
            dueDate: "2023-08-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 250.53,
            interest: 27.08,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1066,
            dueDate: "2023-07-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 245.43,
            interest: 32.18,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1065,
            dueDate: "2023-06-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 240.44,
            interest: 37.17,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1064,
            dueDate: "2023-05-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 235.56,
            interest: 42.05,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1063,
            dueDate: "2023-04-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 230.77,
            interest: 46.84,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1062,
            dueDate: "2023-03-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 226.08,
            interest: 51.53,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1061,
            dueDate: "2023-02-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 221.48,
            interest: 56.13,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1060,
            dueDate: "2023-01-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 216.98,
            interest: 60.63,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1059,
            dueDate: "2022-12-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 212.57,
            interest: 65.04,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1058,
            dueDate: "2022-11-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 208.25,
            interest: 69.36,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1057,
            dueDate: "2022-10-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 204.01,
            interest: 73.6,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1056,
            dueDate: "2022-09-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 199.87,
            interest: 77.74,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1055,
            dueDate: "2022-08-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 195.8,
            interest: 81.81,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1054,
            dueDate: "2022-07-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 191.82,
            interest: 85.79,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1053,
            dueDate: "2022-06-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 187.92,
            interest: 89.69,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1052,
            dueDate: "2022-05-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 184.1,
            interest: 93.51,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1051,
            dueDate: "2022-04-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 180.36,
            interest: 97.25,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1050,
            dueDate: "2022-03-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 176.69,
            interest: 100.92,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1049,
            dueDate: "2022-02-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 173.1,
            interest: 104.51,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1048,
            dueDate: "2022-01-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 169.58,
            interest: 108.03,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1047,
            dueDate: "2021-12-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 166.14,
            interest: 111.47,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1046,
            dueDate: "2021-11-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 162.76,
            interest: 114.85,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1045,
            dueDate: "2021-10-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 159.45,
            interest: 118.16,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1044,
            dueDate: "2021-09-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 156.21,
            interest: 121.4,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1043,
            dueDate: "2021-08-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 153.03,
            interest: 124.58,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1042,
            dueDate: "2021-07-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 149.92,
            interest: 127.69,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1041,
            dueDate: "2021-06-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 146.88,
            interest: 130.73,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1040,
            dueDate: "2021-05-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 143.89,
            interest: 133.72,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1039,
            dueDate: "2021-04-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 140.96,
            interest: 136.65,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1038,
            dueDate: "2021-03-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 138.1,
            interest: 139.51,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1037,
            dueDate: "2021-02-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 135.29,
            interest: 142.32,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1036,
            dueDate: "2021-01-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 132.54,
            interest: 145.07,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1035,
            dueDate: "2020-12-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 129.85,
            interest: 147.76,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1034,
            dueDate: "2020-11-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 127.21,
            interest: 150.4,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1033,
            dueDate: "2020-10-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 124.62,
            interest: 152.99,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1032,
            dueDate: "2020-09-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 122.09,
            interest: 155.52,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1031,
            dueDate: "2020-08-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 119.61,
            interest: 158.0,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1030,
            dueDate: "2020-07-12",
            statementType: null,
            statementStatus: "NOT_GENERATED",
            amortization: 117.17,
            interest: 160.44,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1029,
            dueDate: "2020-06-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 114.79,
            interest: 162.82,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1028,
            dueDate: "2020-05-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 112.46,
            interest: 165.15,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1027,
            dueDate: "2020-04-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 110.17,
            interest: 167.44,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1026,
            dueDate: "2020-03-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 107.93,
            interest: 169.68,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1025,
            dueDate: "2020-02-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 105.74,
            interest: 171.87,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1024,
            dueDate: "2020-01-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 103.59,
            interest: 174.02,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1023,
            dueDate: "2019-12-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 101.48,
            interest: 176.13,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1022,
            dueDate: "2019-11-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 99.42,
            interest: 178.19,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1021,
            dueDate: "2019-10-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 97.4,
            interest: 180.21,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1020,
            dueDate: "2019-09-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 95.42,
            interest: 182.19,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1019,
            dueDate: "2019-08-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 93.48,
            interest: 184.13,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1018,
            dueDate: "2019-07-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 91.58,
            interest: 186.03,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1017,
            dueDate: "2019-06-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 89.72,
            interest: 187.89,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1016,
            dueDate: "2019-05-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 87.9,
            interest: 189.71,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1015,
            dueDate: "2019-04-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 86.11,
            interest: 191.5,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1014,
            dueDate: "2019-03-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 84.36,
            interest: 193.25,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1013,
            dueDate: "2019-02-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 82.64,
            interest: 194.97,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1012,
            dueDate: "2019-01-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 80.96,
            interest: 196.65,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1011,
            dueDate: "2018-12-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 79.32,
            interest: 198.29,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1010,
            dueDate: "2018-11-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 77.7,
            interest: 199.91,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1009,
            dueDate: "2018-10-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 76.13,
            interest: 201.48,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1008,
            dueDate: "2018-09-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 74.58,
            interest: 203.03,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1007,
            dueDate: "2018-08-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 73.06,
            interest: 204.55,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1006,
            dueDate: "2018-07-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 71.58,
            interest: 206.03,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1005,
            dueDate: "2018-06-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 70.12,
            interest: 207.49,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1004,
            dueDate: "2018-05-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 70.11,
            interest: 207.5,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1003,
            dueDate: "2018-04-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 70.11,
            interest: 207.5,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1002,
            dueDate: "2018-03-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 70.11,
            interest: 207.5,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1001,
            dueDate: "2018-02-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 70.11,
            interest: 207.5,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        },
        {
            seriesNum: 1000,
            dueDate: "2018-01-12",
            statementType: null,
            statementStatus: "GENERATED",
            amortization: 70.11,
            interest: 207.5,
            fee: 0.0,
            paidAmount: 0.0,
            totalAmount: 277.61
        }
    ]);
}

export function getVerifyTokenMock(url, method, data) {
    return httpFaker(url, method, data, true);
}

export function getRenewTokenMock(url, method, data) {
    return httpFaker(url, method, data, {
        accessToken: "jf2320fj092jf0932jf"
    });
}

export function getRemainingDebtMock(url, method, data) {
    return httpFaker(url, method, data, {
        ocrNumber: "string",
        remainingDebts: 0,
        excludedCollectionAmount: 0,
        items: [
            {
                paymentReceiver: "string",
                bankAccountNumber: "string",
                remainingPrincipal: 0,
                interest: 10,
                unpaidStatementsAmount: 20,
                remainingStartupFee: 0,
                pendingStatementAmount: 5,
                upcomingStatementFee: 10,
                upcomingInsuranceFee: 0,
                serviceFee: 0
            },
            {
                paymentReceiver: "string",
                bankAccountNumber: "string",
                remainingPrincipal: 0,
                interest: 10,
                unpaidStatementsAmount: 20,
                remainingStartupFee: 0,
                pendingStatementAmount: 5,
                upcomingStatementFee: 10,
                upcomingInsuranceFee: 0,
                serviceFee: 0
            }
        ]
    });
}

export function getDocumentMock(url, method, data) {
    const file = new Blob(["Dummy pdf"], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    console.log(url);
}

export function getTableMock(...lens) {
    const range = (len) => {
        const arr = [];
        for (let i = 0; i < len; i++) {
            arr.push(i);
        }
        return arr;
    };

    const min = 1000000;
    const max = 2000000;

    const firstInvoiceNumber = Math.round(Math.random() * (max - min) + min);
    const now = addDays(new Date(), 5);

    const newInvoiceRow = (i) => {
        const loanPayment = Math.floor(Math.random() * 100);
        const interest = Math.floor(Math.random() * 100);
        const otherPayments = 7;
        const statementAmount = loanPayment + interest + otherPayments;
        const due = addMonths(now, i);
        const parsed = format(due, DEFAULT_FORMAT_DATE);

        return {
            invoiceNumber: firstInvoiceNumber + i,
            definition: namor.generate({ words: 1, numbers: 0 }),
            status: namor.generate({ words: 1, numbers: 0 }),
            dueDate: parsed,
            loanPayment,
            interest,
            otherPayments,
            statementAmount
        };
    };

    const makeDataLevel = (Debth = 0) => {
        const len = lens[Debth];
        console.log(len);
        return range(len).map((d, i) => {
            return {
                ...newInvoiceRow(i)
            };
        });
    };
    console.log(makeDataLevel());

    return makeDataLevel();
}

export function setStatementChannelMock(url, method, data) {
    const randomResponse = Math.random() >= 0.5;
    console.log("Mock random response", randomResponse);
    return httpFaker(url, method, data, randomResponse);
}

export function updateUserInfoMock(url, method, data) {
    const randomResponse = Math.random() >= 0.5;
    console.log("Mock random response", randomResponse);
    return httpFaker(url, method, data, randomResponse);
}
