/* DEPRECATED */ import {
    /* DEPRECATED */ FETCH_PAYMENT_PLANS_SUCCESS,
    /* DEPRECATED */ FETCH_PAYMENT_PLANS_TRIGGER
} from /* DEPRECATED */ "../constants/paymentPlans";
/* DEPRECATED */
/* DEPRECATED */ export function fetchPaymentPlansTrigger() {
    /* DEPRECATED */ return {
        /* DEPRECATED */ type: FETCH_PAYMENT_PLANS_TRIGGER
        /* DEPRECATED */
    };
    /* DEPRECATED */
}
/* DEPRECATED */
/* DEPRECATED */ export function fetchPaymentPlansSuccess(payload) {
    /* DEPRECATED */ return {
        /* DEPRECATED */ type: FETCH_PAYMENT_PLANS_SUCCESS,
        /* DEPRECATED */ payload
        /* DEPRECATED */
    };
    /* DEPRECATED */
}
