import { createSelector } from "reselect";
import { getToken } from "./token";

const token = (state) => getToken(state);

const getDocuments = (state) => state.documents;

export const getDocumentsWithToken = () =>
    createSelector([getDocuments, token], (documents, token) => {
        return { documents, token };
    });
