import styled from "styled-components/macro";
import { colors } from "../../constants/styles";
import { STATEMENT_STATUSES } from "../../constants/statementStatus";

export const StyledStatementContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledStatementInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
`;

export const StyledStatementLink = styled.div`
    text-decoration: underline;
    color: ${(props) => {
        if (props.status) {
            if (props.status === STATEMENT_STATUSES.PAIDINFULL) {
                return colors.ligthGreen;
            } else if (props.status === STATEMENT_STATUSES.OVERDUE) {
                return colors.alertRed;
            }
        }
        return colors.orange;
    }};
    cursor: pointer;
`;

export const StyledStatementText = styled.div`
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;
